import { Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import CollapseHeader from "./CollapseHeader";
import { ReactComponent as DocumentIcon } from "assets/svg/doc_grey.svg";
import format from "date-fns/format";
import Loader from "./Loader";
import { IInvoice } from "../../../store/invoice/invoiceSlice";
import { useState } from "react";
import "antd/dist/antd.min.css";
import "./antCustom.css";
import Preview from "./Preview";

const { Panel } = Collapse;
interface InvoiceListProps {
  loading: boolean;
  invoices: IInvoice[];
}

const InvoicesList = ({ invoices, loading }: InvoiceListProps) => {
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState<string | string[]>();
  const onChangeHandler = (key: string | string[]) => {
    setSelectedKey(key);
    if (key) navigate(`/invoices/${key}`);
    if (!key) navigate(`/invoices`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWarpper}>
        <Collapse
          className={styles.antCollapse}
          expandIconPosition="end"
          accordion
          onChange={(key) => onChangeHandler(key)}
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            invoices.map((invoice) => {
              return (
                <Panel
                  className={[
                    styles.antCollapseItem,
                    selectedKey === invoice.pid && styles.activeHeaderItem,
                  ].join(" ")}
                  header={
                    <CollapseHeader
                      header={invoice.name}
                      status={invoice.status}
                      created={format(
                        new Date(invoice.created),
                        "dd MMMM yyyy"
                      )}
                    />
                  }
                  key={`${invoice.uid}/${invoice.pid}`}
                >
                  <Preview invoice={invoice} />
                </Panel>
              );
            })
          )}
          {invoices.length === 0 && !loading && (
            <div className={styles.noPayment}>
              <DocumentIcon />
              <p>No payment</p>
            </div>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default InvoicesList;
