import { Rule } from 'antd/lib/form';

export interface IForm {
    elemType: 'button' | 'input';
    name?: string;
    text?: string;
    rules?: Rule[];
    label?: string;
    placeholder?: string;
    type?: 'email' | 'password' | 'number' | 'button';
}

export const passwordValidations = [
  {
    name: 'at least 10 characters',
    regExp: '.{10,}',
  },
  {
    name: 'at least 1 number',
    regExp: '.*[0-9].*',
  },
  {
    name: 'at least 1 punctuation character',
    regExp: '.*[^A-Za-z0-9].*',
  },
  {
    name: 'at least 1 uppercase character',
    regExp: '.*[A-Z].*',
  },
  {
    name: 'at least 1 lowercase character',
    regExp: '.*[a-z].*',
  },
];

export const steps = {
    step1:1,
    step2: 2,
}

export const forgotPasswordForm: IForm[] = [
    {
      elemType: 'input',
      name: 'email',
      rules: [
        {
          required: true,
          type: 'email',
          message: 'please input email',
        },
      ],
      label: 'email',
      type: 'email',
      placeholder: 'Email address',
    },
    {
      elemType: 'button',
      text: 'Reset password',
    },
];

export const recoverPasswordForm: IForm[] = [
  {
    elemType: 'input',
    name: 'code',
    rules: [
      {
        required: true,
        message: '',
      },
    ],
    placeholder: 'code sent by email',
    type: 'number',
  },
  {
    elemType: 'input',
    name: 'password',
    rules: [
      ...passwordValidations.map(({ name, regExp }) => ({
        name,
        validator: async (_: unknown, input: string) => {
          const regex = new RegExp(regExp);
          if (!regex.test(input)) {
            return Promise.reject();
          }
        },
      })),
    ],
    placeholder: 'new password',
    type: 'password',
  },
  {
    elemType: 'input',
    name: 'confirm_password',
    rules: [
      {
        required: true,
        message: '',
      },
      ({ getFieldValue }: { getFieldValue: (field: string) => string }) => ({
        validator(_: unknown, value: string) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
      }),
    ],
    placeholder: 'confirm password',
    type: 'password',
  },
  {
    elemType: 'button',
    text: 'Reset password',
  },
];
