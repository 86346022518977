import { Input as AntInput, Tooltip } from 'antd';
import { InputProps, PasswordProps } from 'antd/lib/input';
import { useCallback, useEffect, useRef, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { titleCase } from 'title-case';
import './index.css';

interface IProps {
  error: boolean;
  label?: string;
  labelClassName?: string;
  /** Message to show when input is on focus */
  infoPopOver?: string;
  tooltip?: React.ReactNode;
  onFocused?: (focus: boolean) => void;
  isFocus?: (focus: boolean) => void;
}

const Input = ({
  error,
  labelClassName,
  infoPopOver,
  onFocused,
  isFocus,
  tooltip,
  ...props
}: IProps & InputProps & PasswordProps) => {
  const [showInfo, setShowInfo] = useState(false);

  let { label, placeholder } = props;
  label = label && titleCase(label);
  placeholder = placeholder && titleCase(placeholder);

  const handleShowInfo = useCallback(
    (show: boolean) => {
      onFocused && onFocused(show);
      infoPopOver && setShowInfo(show);
    },
    [infoPopOver, onFocused],
  );
  const inputRef = useRef<any>(null);
  const [labelVisiblity, setLabelVisiblity] = useState('');

  const handleInputBlur = useCallback(() => {
    handleShowInfo(false);
    const element = inputRef.current;
    if (element && element.state) {
      const { value } = element.state;
      if ((value && value !== '') || props.placeholder) {
        setLabelVisiblity('label-visible');
      } else {
        setLabelVisiblity('');
      }
    }
  }, [handleShowInfo, props.placeholder]);

  useEffect(() => {
    handleInputBlur();
  }, [handleInputBlur]);

  const suffix = tooltip ? (
    <Tooltip title={tooltip} placement="bottom">
      <InfoCircleOutlined />
    </Tooltip>
  ) : (
    props.suffix
  );

  const extraProps = {
    ...props,
    onFocus: () => {
      handleShowInfo(true);
      isFocus && isFocus(true);
    },
    onBlur: () => {
      handleInputBlur();
      isFocus && isFocus(false);
    },
    placeholder: placeholder || label,
    ref: inputRef,
    allowClear: true,
    suffix,
  };
  return (
    <div>
      {showInfo && <p className="defaultFormInput-info-label">{infoPopOver}</p>}
      <div className={`defaultFormInput ${error ? 'defaultFormInput--error' : ''}`}>
        <span
          className={`defaultFormInput-label
           ${error ? 'defaultFormInput-label--error' : ''}${
            labelClassName ? ` ${labelClassName}` : ''
          } ${labelVisiblity}`}
        >
          {label}
        </span>

        {props.type === 'password' ? (
          <AntInput.Password className="defaultFormInput-password" {...extraProps} />
        ) : (
          <AntInput className="defaultFormInput-input" {...extraProps} />
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  error: false,
  label: 'Label',
  ...AntInput.defaultProps,
};

export default Input;
