import { IInvoice } from "store/invoice/invoiceSlice";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

Storage.configure({
  customPrefix: {
    public: "",
  },
});

function Preview(props: { invoice: IInvoice }) {
  const [file, setFile] = useState<any>();

  useEffect(() => {
    getInvoice(props.invoice.invoice_url);
  }, [props.invoice.invoice_url]);

  const getInvoice = async (fileKey: string) => {
    const result = await Storage.get(fileKey, { expires: 120 });
    console.log(result);
    setFile(result);
  };

  return (
    <div>
      <h2>File Preview</h2>
      <iframe src={file} height={600} width={"100%"} title="invoiceIframe">
        Your browser doesn't support iframes
      </iframe>
    </div>
  );
}

export default Preview;
