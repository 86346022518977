import { Skeleton } from "antd";

const TableBodyLoader = ({ loading }: { loading: boolean }) => {
  return (
    <>
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
      <Skeleton paragraph={false} active={true} {...{ loading }} />
    </>
  );
};

export default TableBodyLoader;
