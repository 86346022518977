import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './index.module.css';
import { titleCase } from 'title-case';

interface IProps {
  error?: boolean;
  isCreateCard?: boolean;
  className?: string;
}
const PhoneNumberInput = ({ error, specialLabel, className, ...props }: IProps & PhoneInputProps) => {
  return (
    <PhoneInput
      specialLabel={specialLabel && titleCase(specialLabel)}
      containerClass={`${styles.phoneInput} ${className} ${
        error ? styles.error : ''
      }`}
      {...props}
      buttonClass={styles.button__class}
    />
  );
};

export default PhoneNumberInput;
