import Div100vh from "react-div-100vh";
import upload from "../../assets/svg/upload.svg";
import pay from "../../assets/svg/pay.svg";
import invoice from "../../assets/svg/invoice.svg";
import theiaLogo from "../../assets/png/TheiaLogo.png";
import style from "../../rightPanel/auth/auth.module.css";

function AuthPageBanner() {
  return (
    <Div100vh className={style.mainWrapper}>
      <div className={style.radialWrapper}>
        <div>
          <img src={theiaLogo} alt="theiaLogo" className={style.theiaLogo} />
          <div className={style.authTitle}>Theia Pay (Beta)</div>
          <div className={style.authSubTitle}>
            Earn points and preserve cashflow
          </div>
        </div>
        <div className={style.stepperWrapper}>
          <div className={style.stepperItem}>
            <div className={style.stepCounter}>1</div>
            <img src={upload} alt="upload" className={style.stepperIcon} />
            <span className={style.stepperDescription}>
              {" "}
              Upload invoice from your landlord or supplier
            </span>
          </div>
          <div className={style.stepperItem}>
            <div className={style.stepCounter}>2</div>
            <img src={pay} alt="pay" className={style.stepperIcon} />
            <span className={style.stepperDescription}>
              {" "}
              Pay Theia digitally with your credit cards, earn rewards
            </span>
          </div>
          <div className={style.stepperItem}>
            <div className={style.stepCounter}>3</div>
            <img src={invoice} alt="invoice" className={style.stepperIcon} />
            <span className={style.stepperDescription}>
              {" "}
              Theia settles invoice via traditional bank transfer
            </span>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default AuthPageBanner;
