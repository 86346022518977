import styles from "./invoice.module.css";
import { Steps } from "antd";
import { ReactComponent as Upload } from "assets/svg/upload.svg";
import UploadDocuments from "./components/UploadDocuments/UploadDocuments";
import { ReactComponent as Pay } from "assets/svg/pay.svg";
import { ReactComponent as Invoice } from "assets/svg/invoice.svg";
import { ReactComponent as BackArrow } from "assets/svg/backArrow.svg";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;

const CreateInvoice = () => {
  const current = 0;
  const navigate = useNavigate();

  const steps = [
    {
      labelIcon: <Upload />,
      content: <UploadDocuments />,
    },
    {
      labelIcon: <Pay />,
      content: "second-content",
    },
    {
      labelIcon: <Invoice />,
      content: "third-content",
    },
  ];

  const handleBackArrow = () => {
    navigate("/invoices");
  };

  return (
    <div className={styles.invoiceUploadContainer}>
      <div className={styles.backArrow} onClick={handleBackArrow}>
        <BackArrow className={styles.backArrowIcon} />
      </div>
      <h1>Upload</h1>
      <>
        <Steps
          current={current}
          labelPlacement="vertical"
          size="small"
          style={{
            justifyContent: "space-around",
          }}
        >
          {steps.map((item, index) => (
            <Step key={index} description={item.labelIcon} />
          ))}
        </Steps>
        <div>{steps[current].content}</div>
      </>
    </div>
  );
};

export default CreateInvoice;
