import { Typography, Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {TFormError, useHandleFormChange} from "../../../hooks/useHandleFormChange";
import { DefaultFormInput } from "components/CustomInputs";
import { ConfirmEmailFormItem } from './form';
import CustomBtn from 'components/Button';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { confirmEmail, selectAuth, clearUpState } from "../../../store/auth/authSlice";
import openNotification from 'components/Notification';
import styles from './signup.module.css';
import { getAuthError, TAuthError } from "hooks/useAuth";

const { Text } = Typography;

interface IProps {
    userEmail: string;
    cancelConfirmation: () => void;
}

const ConfirmEmail = ({userEmail, cancelConfirmation}:IProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, signup } = useAppSelector(selectAuth);
  const [formErrors, setFormErrors] = useState<TFormError>(null);
  const handleFormChange = useHandleFormChange(form, setFormErrors);
  const pickAuthError = useCallback(getAuthError, []);

  const onFinish = async (values:any) => {
    const data = {
        code: values.code as string,
        username: userEmail,
    }
    await dispatch(confirmEmail(data)).unwrap();
  }

  useEffect(() => {
    if(signup.emailConfirmationSuccess){
       openNotification({
          status: 'success',
          description: 'Email confirmed, Kindly proceed to login',
          key: 'success',
          message: 'Signup Process completed',
        })
        navigate('/auth/signin')
        cancelConfirmation();
    }
    return () => {
      dispatch(clearUpState())
    }
  }, [cancelConfirmation, dispatch, navigate, signup.emailConfirmationSuccess])

  useEffect(() => {
    if(error){
        const authError = pickAuthError(error as TAuthError);
        openNotification({
            status: 'error',
            description: authError as string,
            key: 'error',
            message: 'Error Confirming Email',
        })
    }
    return () => {
      dispatch(clearUpState())
    }
  },[dispatch, error, pickAuthError])


  return (
    <div className={styles.regFormWrapper} data-testid="theia-registration">
        <Text className={styles.regTitle}>
        <h1>Confirm Email</h1>
        <p>Already have an account. Sign in <Link to={'auth/signin'}>here</Link></p>
        </Text>
        <Form
            form={form}
            validateTrigger={['onSubmit', 'onBlur', 'onChange']}
            onFinish={onFinish}
            className={styles.form}
            onFieldsChange={handleFormChange}
        >
            {
                ConfirmEmailFormItem.map((item, i) => (
                    <Form.Item
                        key={i}
                        className={styles.formItem}
                        name={item?.name}
                        rules={item?.rules}
                    >
                        <DefaultFormInput
                          error={formErrors ? !!formErrors?.[item.name] : false}
                          {...item} />
                    </Form.Item>
                ))
            }
            <CustomBtn
                className={styles.btn}
                htmlType="submit"
                logo={false}
                data-testid="submit_btn"
                loading={loading}
            >
                Confirm Email.
            </CustomBtn>
          </Form>
    </div>
  )
}

export default ConfirmEmail;
