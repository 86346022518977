import axios from "axios";
import request from "./request";
import {apiVersion} from "../config";

export const fetchUser = async (uid:string) => {
    try {
        const response = await request.get(`${apiVersion}/admin/theiapay/user/${uid}`);
    
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error(error?.response?.data?.messages)
        }
      }
}