import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { Storage } from "aws-amplify";
import { useAuthentication } from "../../../../../hooks/useAuth";
import { RcFile } from "antd/lib/upload";

const { Dragger } = Upload;
interface IUploadDraggerProps {
  setPath: (value: string) => void;
}

const UploadDragger = (props: IUploadDraggerProps) => {
  const { user } = useAuthentication();
  const uploadFile = async (options: any, docu: string) => {
    try {
      const { onSuccess, file } = options;
      const saveAs = `${new Date().toISOString().slice(0, 10)}-${docu}-${
        file.name
      }`;
      const storageResult = (await Storage.put(saveAs, file, {
        level: "private",
        contentType: file.type,
        customPrefix: {
          private: `private/${user?.attributes?.email}/`,
        },
      })) as unknown as { key: string };
      console.log(storageResult);

      onSuccess("Ok", storageResult);
      message.success(`The file ${file.name} upload successfully`);
      props.setPath(storageResult.key);
    } catch (err) {
      options.onError({ err });
      console.log("error", err);
      message.error(
        `Cannot upload file! Please try again later or contact us.`
      );
    }
  };

  const removeFile = async (file: any) => {
    try {
      await Storage.remove(file.xhr.key, {
        level: "private",
        customPrefix: {
          private: `private/${user?.attributes?.["custom:full_name"]}/`,
        },
      });
      message.success(`The file \`${file.name}\` was deleted successfully`);
    } catch (err) {}
  };

  const checkFile = (file: RcFile) => {
    const acceptedFormats = ["pdf", "doc", "docx", "png", "zip", "jpg", "jpeg"];
    const acceptFileType = acceptedFormats.includes(
      (file as File).type.split("/")[1].toLowerCase()
    );

    if (!acceptFileType) message.error("The file type is not accepted.");

    return acceptFileType ? true : Upload.LIST_IGNORE;
  };

  return (
    <Dragger
      customRequest={async (options) => {
        await uploadFile(options, `custom`);
      }}
      beforeUpload={checkFile}
      onRemove={removeFile}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for a single upload</p>
    </Dragger>
  );
};

export default UploadDragger;
