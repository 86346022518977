export const apiVersion = process.env.REACT_APP_API_VERSION || 'v1';
export const appVersion = process.env.REACT_APP_VERSION || '0.0.1';
export const env = (process.env.REACT_APP_ENV as keyof typeof appConfig) || 'GITSTART';

interface IConfig {
  endpoint: string;
  awsConfig: {
    region?: string;
    userPoolId?: string;
    appClientId?: string;
    userPoolWebClientId?: string;
    identityPoolId?: string;
    Auth?: {
      region: string;
      userPoolId: string;
      appClientId: string;
      userPoolWebClientId: string;
      identityPoolId: string;
    };
    Storage?: {
      AWSS3: {
        bucket: string;
        region: string;
      };
    };
  };
}
const appConfig = {
  DEV: {
    endpoint: `https://ebs5m2i9pl.execute-api.ap-east-1.amazonaws.com/latest/`,
    awsConfig: {
      Auth: {
        region: "ap-northeast-1",
        userPoolId: "ap-northeast-1_JxtMsGKh0",
        appClientId: "5r1ju72bjf775japo172r62sa6",
        userPoolWebClientId: "5r1ju72bjf775japo172r62sa6",
        identityPoolId: "ap-northeast-1:b239c631-7519-41dc-ad35-cc9fe205301f",
      },
      Storage: {
        AWSS3: {
          bucket: "theia-pay-upload-findev", //REQUIRED -  Amazon S3 bucket name
          region: "ap-east-1", //OPTIONAL -  Amazon service region
        },
      },
    },
  },
  PROD:{
    endpoint: `https://swokvp0fpl.execute-api.ap-east-1.amazonaws.com/latest/`,
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_dBovd7mdw',
        appClientId: '2ihta4j2edomptppajq1ah6hpi',
        userPoolWebClientId: '2ihta4j2edomptppajq1ah6hpi',
        identityPoolId: 'ap-northeast-1:f80a5712-8413-4758-b6a8-099dc2aa7312',
      },
      Storage: {
        AWSS3: {
          bucket: 'theiapay-upload', //REQUIRED -  Amazon S3 bucket name
          region: 'ap-east-1', //OPTIONAL -  Amazon service region
        },
      },
    },
  },
};

const config: IConfig = appConfig[env];

if (!config) {
  throw new Error(`Invalid environment: ${process.env.REACT_APP_ENV}`);
}

export default config;
