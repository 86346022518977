


function UserTable(prop: { userDetails: any }) {
    return (
        <table>
            <tr>
                <td><b>User ID: </b>
                </td>
                <td>
                    {prop.userDetails?.uid}
                </td>
            </tr>
            <tr>
                <td>
                    <b>Email: </b>
                </td>
                <td>
                    {prop.userDetails?.email}
                </td>
            </tr>
            <tr>
                <td>
                    <b>Phone: </b>
                </td>
                <td>
                    {prop.userDetails?.phone_number}
                </td>
            </tr>
            <tr>
                <td>
                    <b>Name: </b>
                </td>
                <td>
                    {prop.userDetails?.full_name}
                </td>
            </tr>
        </table>
    )
}

export default UserTable;