import { Typography, Form } from "antd";
import styles from './signup.module.css';
import CustomBtn from 'components/Button';
import { RegFormItem } from "./form";
import { DefaultFormInput, ReactPhoneInput } from "components/CustomInputs";
import {Link} from "react-router-dom";
import PasswordStrength from "../../../components/PasswordStrength";
import {useCallback, useEffect, useState} from "react";
import {TFormError, useHandleFormChange} from "../../../hooks/useHandleFormChange";
import {IPasswordStrengthValidationObject} from "../../../components/PasswordStrength/PasswordStrength";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {clearUpState, selectAuth, signUp} from "../../../store/auth/authSlice";
import {ICreateAccount} from "../../../utils/auth";
import ConfirmEmail from "./ConfirmEmail";
import openNotification from "../../../components/Notification";

const { Text } = Typography;

interface ITheiaPasswordForm {
  password?: string;
}

const SignUp = () => {
  const [form] = Form.useForm();
  const [userEmail, setUserEmail] = useState('');
  const { loading, error, signup } = useAppSelector(selectAuth);

  const dispatch = useAppDispatch();

  const onFinish = useCallback(
    async (values: ICreateAccount) => {
      setUserEmail(values.email);
      return dispatch(signUp({
        ...values,
        phone_number: values.phone_number.replace(/[- )(]/g,''),
      })).unwrap();
    }, [dispatch],
  )

  useEffect(() => {
    if(error?.code) {
      openNotification({
        message: 'Error signing up',
        key: 'signupError',
        status: 'error',
        description: error.message
      });
    }
    return () => {
      dispatch(clearUpState());
    }
  } , [error, dispatch]);

  useEffect(() => {
    if(signup.success) {
      setShowConfirmationComponent(true);
    }
  }, [signup.success])

  const [formError, setFormErrors] = useState<TFormError>(null);
  const [password, setPassword] = useState<ITheiaPasswordForm>({password: ''});
  const [showConfirmationComponent, setShowConfirmationComponent] = useState(false);
  const handleFormChange = useHandleFormChange(form, setFormErrors, setPassword);
  const passwordFormItem = RegFormItem.fields?.filter(({ name }) => name === 'password')[0];

  const cancelShowConfirmationComponent = useCallback(
    () => {
      setShowConfirmationComponent(false);
    },[])

  return (
    <>
      {
        showConfirmationComponent ? (
          <ConfirmEmail userEmail={userEmail} cancelConfirmation={cancelShowConfirmationComponent} />
        ):
        (
          <div className={styles.regFormWrapper} data-testid="theia-registration">
          <Text className={styles.regTitle}>
            <h1>Sign up</h1>
            <p>Already have an account. Sign in <Link to='/auth/signin'>here</Link></p>
          </Text>

          <Form
             form={form}
              validateTrigger={['onSubmit', 'onBlur', 'onChange']}
              onFinish={onFinish}
              className={styles.form}
              onFieldsChange={handleFormChange}
          >
              <div className={styles.regFormInputWrapper}>
                {(RegFormItem.fields || []).map((item, i) => (
                  <Form.Item
                    key={i}
                    className={styles.formItem}
                    name={item?.name}
                    rules={item?.rules}
                  >
                    {item.name === 'phone_number' ? (
                      <div data-testid={`reg_${item.name}`}>
                        <ReactPhoneInput
                          preferredCountries={['hk', 'cn', 'au', 'mo']}
                          country="hk"
                          specialLabel="phone number"
                          placeholder={item?.placeholder}
                        />
                      </div>
                    ) : (
                      <DefaultFormInput
                        error={formError ? !!formError[item.name] : false}
                        label={item?.label || ''}
                        labelClassName={styles.label}
                        type={item.type}
                        infoPopOver={item.infoPopOver}
                        placeholder={item?.placeholder?.toLocaleLowerCase()}
                        data-testid={`reg_${item.name}`}
                      />
                    )}
                  </Form.Item>
                ))}

                <div className={styles.passwordValidation}>
                  <PasswordStrength
                    rules={passwordFormItem?.rules as IPasswordStrengthValidationObject[]}
                    password={password.password}
                  />
                </div>
              </div>
              <CustomBtn
                className={styles.btn}
                htmlType="submit"
                logo={false}
                data-testid="submit_btn"
                loading={loading}
              >
                Join now
              </CustomBtn>
            </Form>
        </div>
        )
      }
    </>
  );
}

export default SignUp;
