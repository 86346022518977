import { notification } from 'antd';
import { ReactComponent as SuccessIcon } from './icons/success-icon.svg';
import { ReactComponent as ErrorIcon } from './icons/error-icon.svg';
import { ReactComponent as WarningIcon } from './icons/warning-icon.svg';
import { ReactComponent as InfoIcon } from './icons/info-icon.svg';
import { ReactComponent as CloseIcon } from './icons/close-icon.svg';
import './index.css';

interface IToastArgs {
  message?: string;
  description: string;
  key: string;
  persist?: boolean;
  status?: TNotificationStatus;
}

export type TNotificationStatus = 'primary' | 'success' | 'warning' | 'error';

export default function openNotification({
  key,
  description,
  message,
  persist = false,
  status = 'primary',
}: IToastArgs) {
  notification.close(key);
  const NotificationStatus = status === 'primary' ? 'info' : status;

  let icon: React.ReactNode;
  let className = 'notify';

  switch (status) {
    case 'primary':
      message = message || 'Info';
      icon = <InfoIcon />;
      className += ' info';
      break;
    case 'success':
      message = message || 'Success';
      icon = <SuccessIcon />;
      className += ' success';
      break;
    case 'warning':
      message = message || 'Warning';
      icon = <WarningIcon />;
      className += ' warning';
      break;
    case 'error':
      message = message || 'Error';
      icon = <ErrorIcon />;
      className += ' error';
      break;
  }

  notification[NotificationStatus]({
    message,
    description,
    key,
    className,
    icon,
    duration: persist ? 0 : 4.5,
    closeIcon: <CloseIcon />,
  });
}
