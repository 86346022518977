import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "./auth.module.css";
import CustomBtn from "components/Button";
import { DefaultFormInput } from "components/CustomInputs";
import { useCallback, useEffect, useState } from "react";
import { User } from "../../utils/auth";
import {
  clearUpState,
  completeNewPassword,
  selectAuth,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAuthError, TAuthError } from "../../hooks/useAuth";
import openNotification from "components/Notification";
import { useHandleFormChange } from "../../hooks/useHandleFormChange";

interface IFormError {
  [index: string]: string[];
}

type TFormError = IFormError | null;
function ResetPassword() {
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();
  const { loading, error, user: storeUser } = useAppSelector(selectAuth);

  const dispatch = useAppDispatch();
  const handleFormChange = useHandleFormChange(form, setFormErrors);

  const navigate = useNavigate();
  const handleFinish = useCallback(
    async (values: { password: string; confirm_password: string }) => {
      if (values.password === values.confirm_password) {
        const params = {
          user: storeUser as User,
          password: values.password,
        };
        const user = await dispatch(completeNewPassword(params)).unwrap();
        if (user) {
          navigate("/invoices");
        }
      } else {
        openNotification({
          message: "Password not match",
          key: "loginError",
          status: "error",
          description: "Plase check the password",
        });
      }
    },
    [dispatch, navigate, storeUser]
  );

  const pickAuthError = useCallback(getAuthError, []);

  useEffect(() => {
    if (error?.message) {
      const authError = pickAuthError(error as TAuthError);

      openNotification({
        message: "Error signing in",
        key: "loginError",
        status: "error",
        description: authError as string,
      });
    }

    return () => {
      dispatch(clearUpState());
    };
  }, [error, pickAuthError, dispatch]);

  return (
    <div data-testid="theia-login" className={styles.wrapper}>
      <Form
        role="form"
        onFinish={handleFinish}
        form={form}
        className={styles.container}
        onFieldsChange={handleFormChange}
        validateTrigger={["onSubmit", "onChange"]}
      >
        <div className={styles.innerwrap}>
          <div className={`caption_ ${styles.caption}`}>
            <h2>Theia Pay Admin</h2>
            <h1>Reset</h1>
          </div>
          <p className={styles.recoverLink}>
            You are required to change your password immediately. Please enter
            the password.
          </p>
          <div className={styles.inputBox}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "please input password",
                },
              ]}
            >
              <DefaultFormInput
                error={formError ? !!formError["password"] : false}
                label="New Password"
                labelClassName={styles.label}
                type="password"
                data-testid="login_pwd"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <DefaultFormInput
                error={formError ? !!formError["password"] : false}
                label="Confirm Password"
                labelClassName={styles.label}
                type="Password"
                data-testid="login_pwd"
              />
            </Form.Item>
            <Form.Item className={styles.formbtn}>
              <CustomBtn
                disabled={!!formError}
                loading={loading}
                htmlType="submit"
                className={styles.submitBtn}
              >
                Reset
              </CustomBtn>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ResetPassword;
