import {FormInstance} from "antd";
import {Dispatch, SetStateAction, useCallback} from "react";


export interface IFormError {
  [index: string]: string[];
}
export type TFormError = IFormError | null;

export const useHandleFormChange = (
  form: FormInstance<any>,
  setFormErrors: Dispatch<SetStateAction<TFormError>>,
  setPassword?: Dispatch<SetStateAction<{ password?: string }>>,
) => {
  const formChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object?.keys(errors).length ? errors : null);
    setPassword && setPassword({ password: form.getFieldValue('password') });
  }, [form, setFormErrors, setPassword]);
  return formChange;
};
