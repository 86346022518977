import { useEffect } from "react";
import {
  getInvoicesAsync,
  selectInvoices,
} from "../../../store/invoice/invoiceSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import InvoicesList from "./list";
import openNotification from "../../../components/Notification";
import { AppDispatch } from "../../../store/store";

export default function Invoice() {
  const { invoicesList, loading, error } = useAppSelector(selectInvoices);
  const dispatch = useAppDispatch() as AppDispatch;

  useEffect(() => {
    dispatch(getInvoicesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (error?.message) {
      openNotification({
        key: "invoice_error",
        message: "Error while fetching invoice",
        description: error.message,
        status: "error",
      });
      console.log(error);
    }
  }, [error]);
  return (
    <div>
      <InvoicesList invoices={invoicesList} loading={loading} />
    </div>
  );
}
