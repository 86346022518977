import { Affix } from "antd";
import { useAuthentication } from "hooks/useAuth";
import { ReactComponent as Coffee } from "assets/svg/coffee.svg";

import style from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { signOut } from "store/auth/authSlice";
import { useAppDispatch } from "store/hooks";

function SignoutBtn() {
  const dispatch = useAppDispatch();
  const { user } = useAuthentication();
  const navigate = useNavigate();

  async function onClickSignOut() {
    try {
      dispatch(signOut());
      navigate("/auth/signin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  if (user) {
    return (
      <Affix className={style.wrapper}>
        <button className={style.button} onClick={onClickSignOut}>
          <Coffee />
          Sign Out
        </button>
      </Affix>
    );
  }

  return <></>;
}

export default SignoutBtn;
