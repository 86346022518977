import { Rule } from 'antd/lib/form';

type TName =
  | 'company_name'
  | 'email'
  | 'full_name'
  | 'password'
  | 'phone_number'
  | 'confirmPassword'
  | 'code';

interface IFormfields {
  name: TName;
  type: string;
  label: string;
  placeholder?: string;
  infoPopOver?: string;
  dependencies?: string[];
  rules?: Rule &
    {
      required?: boolean;
      message?: string;
      name?: string;
      validator?: (_: unknown, value: string) => Promise<undefined>;
    }[];
}
export interface IRegFormItem {
  action?: string;
  title?: string;
  subTitle?: string;
  formDescription?: string;
  showBackArrow?: boolean;
  fields?: IFormfields[];
}

export const passwordValidations = [
  {
    name: 'at least 10 characters',
    regExp: '.{10,}',
  },
  {
    name: 'at least 1 number',
    regExp: '.*[0-9].*',
  },
  {
    name: 'at least 1 punctuation character',
    regExp: '.*[^A-Za-z0-9].*',
  },
  {
    name: 'at least 1 uppercase character',
    regExp: '.*[A-Z].*',
  },
  {
    name: 'at least 1 lowercase character',
    regExp: '.*[a-z].*',
  },
];

export const RegFormItem: IRegFormItem = {
  title: 'Get started by creating your account',
  formDescription: 'Please enter the required information below',
  showBackArrow: true,
  fields: [
    {
      name: 'email',
      type: 'email',
      label: 'Email address',
      rules: [
        {
          required: true,
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
      ],
    },
    {
      name: 'phone_number',
      type: '',
      label: 'Phone number',
      rules: [
        {
          required: true,
          message: 'Phone number is required',
          validator: async (_: unknown, input: string) => {
            if (input.length < 9) return Promise.reject('Input too short');
          },
        },
      ],
    },
    {
      name: 'full_name',
      type: 'text',
      label: 'Full name',
      rules: [
        {
          required: true,
          message: 'Input too short',
          validator: async (_: unknown, input: string) => {
            if (input.length < 2) return Promise.reject('Input too short');
          },
        },
      ],
    },
    {
      name: 'company_name',
      type: 'text',
      label: 'Full name of company(optional)',
    },
    {
      name: 'password',
      type: 'password',
      label: 'choose a password',
      rules: [
        ...passwordValidations.map(({ name, regExp }) => ({
          name,
          validator: async (_: unknown, input: string) => {
            const regex = new RegExp(regExp);
            if (!regex.test(input)) {
              return Promise.reject();
            }
          },
        })),
      ],
    },
    {
      name: 'confirmPassword',
      type: 'password',
      label: 'confirm password',
      rules: [
        {
          required: true,
          message: '',
        },
        ({ getFieldValue }: { getFieldValue: (field: string) => string }) => ({
          validator(_: unknown, value: string) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
        }),
      ],
    },
  ],
};

export const ConfirmEmailFormItem : IFormfields[] = [{
  name: 'code',
  rules: [
    {
      required: true,
      message: 'Please input your code',
    },
  ],
  placeholder: 'code sent to email',
  type: 'number',
  label: ''
}];
