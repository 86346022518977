import { useState, useEffect, useCallback} from 'react';
import { Form } from "antd";
import PasswordFormLayout from './PasswordFormLayout';
import { forgotPasswordForm as FormElements, steps as Procedures, recoverPasswordForm } from './forms';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHandleFormChange } from 'hooks/useHandleFormChange';
import {
  sendConfirmation,
  resetPassword as resetPasswordAction,
  selectAuth,
  sendingOTPInProgress,
  passwordResetInProgress } from 'store/auth/authSlice';
import openNotification from 'components/Notification';
import { useNavigate } from 'react-router-dom';
import { getAuthError } from 'hooks/useAuth';

export interface IFormError {
  [index: string]: string[];
}

export type TFormError = IFormError | null;

function Recover() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<{ password?: string }>({});
  const { resetPassword } = auth;
  const [formError, setFormErrors] = useState<{
    [index: string]: string[];
  } | null>(null);

  useEffect(() => {
    setStep(Procedures.step1);
  }, []);

  const pickAuthError = useCallback(getAuthError, []);
  const handleOnFinishForgotPassword = (values: any) => {
    setEmail(values.email);
    dispatch(sendingOTPInProgress(values.email));
    dispatch(sendConfirmation(values.email));
    if(resetPassword.otpSent === true) {
      setStep(Procedures.step2)
    }
  }

  useEffect(() => {
    if (resetPassword.error) {
      openNotification({
        message: 'Error resetting password',
        key: 'resetPasswordError',
        status: 'error',
        description: resetPassword.error as unknown as string
      });
    }
  }, [pickAuthError, resetPassword]);

  useEffect(() => {
    if(resetPassword.otpSent) {
      setStep(Procedures.step2)
    }
  }, [resetPassword.otpSent])

  useEffect(() => {
    if(resetPassword.isComplete){
      openNotification({
        status: 'success',
        description: 'Kindly login with your new password',
        key: 'success',
        message: 'Password reset successfully',
      })
      navigate('/auth/signin')
    }
  }, [resetPassword.isComplete, navigate])

  const handleOnFinishRecoverPassword = (values: { password: string; code: string }) => {
    const payload = {
      password: values.password,
      code: values.code,
      username: email,
    }
    dispatch(passwordResetInProgress())
    dispatch(resetPasswordAction(payload));
  }

  const handleForgotPasswordChange = useHandleFormChange(form, setFormErrors);
  const handelResetPasswordChange = useHandleFormChange(form, setFormErrors, setPassword);

  return (
    <div>
      {step === Procedures.step1 ? (
        <PasswordFormLayout
          form={form}
          InputFields={FormElements}
          title={"Forgot Password"}
          currentStep={step}
          changeStep={setStep}
          subTitle={"Please provide your email address"}
          onFinish={handleOnFinishForgotPassword}
          formError={formError}
          onFieldsChange={handleForgotPasswordChange}
          loading={resetPassword.loading}
        />
      ) : step === Procedures.step2 ? (
        <PasswordFormLayout
          form={form}
          InputFields={recoverPasswordForm}
          title={"Reset Password"}
          currentStep={step}
          changeStep={setStep}
          onFinish={handleOnFinishRecoverPassword as (val: { [index: string]: unknown }) => void}
          onFieldsChange={handelResetPasswordChange}
          password={password.password}
          loading={resetPassword.loading}
          formError={formError}
         />
      ): null}
    </div>
  )
}

export default Recover;
