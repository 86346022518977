import { useState, useEffect, useCallback } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import {User} from "../utils/auth";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {currentUser, selectAuth} from "../store/auth/authSlice";
import openNotification from "../components/Notification";

export type TAuthError = string | { message?: string };

export const getAuthError = (error: TAuthError) => {
  if (typeof error === 'string') return error;
  else {
    return error.message;
  }
};

interface IAuth {
  user: User | null;
  userPayload: { [id: string]: unknown };
  isAuthenticating: boolean;
}

export const useAuthentication = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const history = useNavigate();

  const { user } = useAppSelector(selectAuth);

  const [auth, setAuth] = useState<IAuth>({
    user,
    userPayload: {},
    isAuthenticating: true,
  });
  const [error, setError] = useState<unknown | null>(null);

  const updateUser = useCallback(
    (user: User) => {
      dispatch(currentUser(user));
    },
    [dispatch],
  );

  const getAuth = useCallback(
    async (shouldUpdateUser?: boolean) => {
      try {
        await Auth.currentSession();
        const user = await Auth.currentUserPoolUser();
        shouldUpdateUser && updateUser(user);
        setAuth((prevState) => ({
          ...prevState,
          user,
          userPayload: user.getSignInUserSession()?.getIdToken().decodePayload() || {},
          isAuthenticating: false,
        }));
      } catch (err) {
        setError(err);
      }
    },
    [updateUser],
  );

  const pickAuthError = useCallback(getAuthError, []);

  useEffect(() => {
    if (error) {
      const authError = pickAuthError(error as TAuthError);
      const shouldNotify = location.pathname !== '/auth/signin' && authError !== 'No current user';

      if (shouldNotify) {
        openNotification({
          message: `Request Error: ${authError}`,
          description: 'please login again',
          key: authError || 'auth error',
          status: 'error',
        });
      }

      setAuth({
        user: null,
        userPayload: {},
        isAuthenticating: false,
      });
    }
  }, [error, history, location.pathname, pickAuthError]);

  useEffect(() => {
    setAuth((prevState) => ({ ...prevState, isAuthenticating: true }));
  }, [history, user]);

  useEffect(() => {
    auth.isAuthenticating && getAuth();
    if (auth.user && !user) {
      getAuth(true);
    }
  }, [auth, getAuth, user]);

  return auth;
};
