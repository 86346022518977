import { Dispatch, SetStateAction } from "react";
import { Form, FormInstance } from "antd";
import { Link, useNavigate } from 'react-router-dom';
import styles from './recover.module.css';
import { IForm, steps as Procedures } from './forms';
import {ReactComponent as BackArrow} from 'assets/svg/backArrow.svg';
import Button from 'components/Button';
import { DefaultFormInput } from 'components/CustomInputs';
import PasswordStrength from "components/PasswordStrength";
import { IPasswordStrengthValidationObject } from "components/PasswordStrength/PasswordStrength";

interface IOnfinish {
  [index: string]: unknown;
}
interface IFormProps {
    form: FormInstance<any>;
    onBack?: () => void;
    InputFields: IForm[];
    onFinish: (formitems: IOnfinish) => void;
    onFieldsChange: () => void;
    title: string;
    loading?: boolean;
    currentStep: number;
    subTitle?: string;
    changeStep: Dispatch<SetStateAction<number>>;
    password?:string;
    formError: {
      [index: string]: string[];
    } | null;
}

const PasswordFormLayout = (props: IFormProps) => {
    const navigate = useNavigate();
    const handleBackArrow = () => {
      if(props.currentStep === Procedures.step1) {
        navigate('/auth/signin');
      }
      if(props.currentStep === Procedures.step2) {
        props.changeStep(Procedures.step1);
      }
    }

    return (
        <div className={styles.recoverOverallContainer}>
          <div className={styles.backArrow} onClick={handleBackArrow}>
            <BackArrow className={styles.backArrowIcon} />
          </div>
          <Form
          role="form"
          onFinish={props.onFinish}
          form={props.form}
          onFieldsChange={props.onFieldsChange}
          >
            <div className={styles.innerBody}>
                <div className={styles.description}>
                    <h2 className={styles.title}>{props.title}</h2>
                    {props.subTitle && <p className={styles.subTitle}>{props.subTitle}</p>}
                </div>
                {props.InputFields.map(({ elemType, name, placeholder, type, rules, text }, index) => elemType === 'input' ? (
                  <Form.Item {...{
                    ...(name ? { name: name } : {}),
                    ...(rules ? { rules: rules } : {}),
                    }}
                    key={`${name}_${index}`}
                  >
                      <DefaultFormInput
                        label={placeholder as string}
                        type={type}
                      />
                  </Form.Item>
                  ) : (
                  <Form.Item key={index} className={styles.button}>
                    <Button
                      htmlType="submit"
                      loading={props.loading}
                      className={styles.button}
                      disabled={props.loading || !!props.formError}
                    >
                        {text}
                    </Button>
                  </Form.Item>
                ))}
                <p className={styles.recoverLink}>
                  Remembered your password? <Link to="/auth/signin">Sign in</Link>
                </p>
                {Procedures.step2 === props.currentStep && (
                    <PasswordStrength
                        rules={props.InputFields[1].rules as IPasswordStrengthValidationObject[]}
                        password={props.password}
                    />
                )}
            </div>
          </Form>
        </div>
      )
}

export default PasswordFormLayout
