import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackArrow } from "assets/svg/backArrow.svg";

import style from "./index.module.css";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  focuseInvoiceAsync,
  selectInvoices,
  STATUS,
  updateInvoiceAsync,
} from "store/invoice/invoiceSlice";
import { Button, Form, Input, InputNumber, Select, Spin } from "antd";
import UserTable from "./components/userTable";

function ViewInoice() {
  const { uid, pid } = useParams() as { uid: string; pid: string };
  const dispatch = useAppDispatch();
  const { focusedInvoice, loading, invoicesList } =
    useAppSelector(selectInvoices);
  const navigate = useNavigate();

  const handleBackArrow = () => {
    navigate("/invoices");
  };

  useEffect(() => {
    dispatch(focuseInvoiceAsync({ uid, pid }));
  }, [dispatch, uid, pid, invoicesList]);

  if (loading) {
    return <Spin />;
  }

  const onFinish = (values: any) => {
    console.log(values);
    dispatch(updateInvoiceAsync(values));
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className={style.backArrow} onClick={handleBackArrow}>
          <BackArrow className={style.backArrowIcon} />
        </div>
        <h1>Invoice</h1>
        <h2>User Details</h2>
        <UserTable userDetails={focusedInvoice.userDetails} />
        <h2>Invoices Details</h2>
      </Spin>
      <Form onFinish={onFinish} name="invoice">
        <Form.Item
          label="User ID"
          name="uid"
          initialValue={focusedInvoice.invoiceDetails?.uid}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Invoices ID"
          name="pid"
          initialValue={focusedInvoice.invoiceDetails?.pid}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Invoices Name"
          name="name"
          initialValue={focusedInvoice.invoiceDetails?.name}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Created At"
          name="created"
          initialValue={focusedInvoice.invoiceDetails?.created}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Last Update"
          name="updated"
          initialValue={focusedInvoice.invoiceDetails?.updated}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Payment Link"
          name="paymentLink"
          initialValue={focusedInvoice.invoiceDetails?.paymentLink}
        >
          <Input
            disabled={
              focusedInvoice.invoiceDetails?.status === STATUS.REVIEWING
                ? false
                : true
            }
          />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          initialValue={focusedInvoice.invoiceDetails?.amount}
          rules={[{ type: "number", min: 0 }]}
        >
          <InputNumber
            disabled={
              focusedInvoice.invoiceDetails?.status === STATUS.REVIEWING
                ? false
                : true
            }
          />
        </Form.Item>
        <Form.Item
          label="Fees"
          name="fees"
          initialValue={focusedInvoice.invoiceDetails?.fees}
          rules={[{ type: "number", min: 0 }]}
        >
          <InputNumber
            disabled={
              focusedInvoice.invoiceDetails?.status === STATUS.REVIEWING
                ? false
                : true
            }
          />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          initialValue={focusedInvoice.invoiceDetails?.status}
        >
          <Select>
            <Select.Option value={STATUS.SETTLED}>Settled</Select.Option>
            <Select.Option value={STATUS.REVIEWING}>Reviewing</Select.Option>
            <Select.Option value={STATUS.SENT_PAY_LINK}>
              Sent pay link
            </Select.Option>
            <Select.Option value={STATUS.READY_TO_PAY}>
              Ready to pay
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Button">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ViewInoice;
