import Invoice from "pages/invoices/components/index";
import PageNav from "components/nav";

const InvoicesPage = () => {
  return (
    <div>
      <PageNav />
      <Invoice />
    </div>
  );
};

export default InvoicesPage;
