import buttonStyles from './Button.module.css';
import { Button as AntBtn } from 'antd';
import React from 'react';
import { sentenceCase } from 'sentence-case';

const defaultProps = {
  children: '',
  type: 'primary',
  className: '',
  loading: false,
  htmlType: 'button',
  disabled: false,
  logo: false,
  ...AntBtn.defaultProps,
};

interface ButtonProps {
  children?: string;
  type: 'primary' | 'secondary' | 'purple' | 'grey';

  className?: string;
  loading?: boolean;
  disabled?: boolean;
  logo?: boolean;
  icon?: string;
  IsNormalCase?: boolean;
}

const Button = ({
  children,
  type,
  className,
  disabled,
  logo,
  loading,
  icon,
  IsNormalCase,
  ...rest
}: ButtonProps) => (
  <AntBtn
    {...rest}
    className={`${className}  ${buttonStyles.btn} ${
      !disabled && !loading ? buttonStyles[type] : ''
    }  ${
      (disabled && type === 'primary') || (loading && type === 'primary')
        ? buttonStyles.primarDisabled
        : buttonStyles.secondaryDisabled
    }`}
    disabled={disabled || loading}
    loading={loading}
  >
    {icon && (
      <div
        className={`${
          disabled && type === 'secondary'
            ? buttonStyles.secondaryDisabledIconContainer
            : type === 'primary'
            ? buttonStyles.primaryIconContainer
            : buttonStyles.secondaryIconContainer
        }`}
      >
        <img src={icon} alt="icon" width="20" height="20"></img>
      </div>
    )}
    {children && <> {IsNormalCase ? children : sentenceCase(children, { stripRegexp: /a^/ })}</>}
    {logo && !loading ? (
      <div className={buttonStyles.logoContainer}>
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
          <path
            d="M1 1L6 6L1 11"
            stroke={
              disabled && type === 'secondary'
                ? '#ADADAD'
                : loading || type === 'secondary'
                ? '#333333'
                : 'white'
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null}
  </AntBtn>
);

Button.defaultProps = defaultProps;

export default Button;
