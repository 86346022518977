import doc from "assets/svg/document.svg";
import styles from "./index.module.css";
interface propsCollapseHeader {
  header: string;
  created: string;
  status: string;
}
export default function CollapseHeader({
  header,
  created,
  status,
}: propsCollapseHeader) {
  return (
    <div className={styles.headerContainer}>
      <img src={doc} alt="" />
      <div className={styles.rightHeader}>
        <p className={styles.drop_down_header}>{header}</p>
        <p className={styles.drop_down_date}>
          {created}
          <br />
          Status: {status}
        </p>
      </div>
    </div>
  );
}
