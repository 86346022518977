import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "./auth.module.css";
import CustomBtn from "components/Button";
import { DefaultFormInput } from "components/CustomInputs";
import { useCallback, useEffect, useState } from "react";
import { IUserSignIn } from "../../utils/auth";
import { clearUpState, login, selectAuth } from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAuthError,
  TAuthError,
  useAuthentication,
} from "../../hooks/useAuth";
import openNotification from "components/Notification";
import { useHandleFormChange } from "../../hooks/useHandleFormChange";

interface IFormError {
  [index: string]: string[];
}

type TFormError = IFormError | null;
function SignIn() {
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();
  const { loading, error } = useAppSelector(selectAuth);

  const { user: currentUser } = useAuthentication();

  const dispatch = useAppDispatch();
  const handleFormChange = useHandleFormChange(form, setFormErrors);

  const navigate = useNavigate();
  const handleFinish = useCallback(
    async (values: IUserSignIn) => {
      const user = await dispatch(login(values)).unwrap();
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        openNotification({
          message: "Required to change password",
          key: "loginWarning",
          status: "warning",
          description:
            "You are required to change new password for your account!",
        });
        navigate("/auth/reset");
      } else {
        navigate("/invoices");
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (currentUser) {
      navigate("/invoices");
    }
  }, [navigate, currentUser]);

  const pickAuthError = useCallback(getAuthError, []);

  useEffect(() => {
    if (error?.message) {
      const authError = pickAuthError(error as TAuthError);

      openNotification({
        message: "Error signing in",
        key: "loginError",
        status: "error",
        description: authError as string,
      });
    }
    return () => {
      dispatch(clearUpState());
    };
  }, [error, pickAuthError, dispatch]);

  return (
    <div data-testid="theia-login" className={styles.wrapper}>
      <Form
        role="form"
        onFinish={handleFinish}
        form={form}
        className={styles.container}
        onFieldsChange={handleFormChange}
        validateTrigger={["onSubmit", "onChange"]}
      >
        <div className={styles.innerwrap}>
          <div className={`caption_ ${styles.caption}`}>
            <h2>Theia Pay Admin</h2>
            <h1>Signin</h1>
          </div>
          <p className={styles.recoverLink}>
            Don't have an account? Signup <Link to="/auth/signup">here</Link>
          </p>
          <div className={styles.inputBox}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "please input email",
                },
              ]}
            >
              <DefaultFormInput
                error={formError ? !!formError["username"] : false}
                label="Email"
                labelClassName={styles.label}
                type="email"
                data-testid="login_email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "please input password",
                },
              ]}
            >
              <DefaultFormInput
                error={formError ? !!formError["password"] : false}
                label="Password"
                labelClassName={styles.label}
                type="password"
                data-testid="login_pwd"
              />
            </Form.Item>
            <Form.Item className={styles.formbtn}>
              <CustomBtn
                disabled={!!formError}
                loading={loading}
                htmlType="submit"
                className={styles.submitBtn}
              >
                Login
              </CustomBtn>
            </Form.Item>
            <div className={styles.recoverLink}>
              Forgotten Password? Recover <Link to="/auth/recover">here</Link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default SignIn;
