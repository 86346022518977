import request from "./request";
import axios from "axios";
import {IInvoice, IInvoiceRequest} from "../store/invoice/invoiceSlice";
import {apiVersion} from "../config";

export async function fetchInvoices() {
  try {
    const response = await request.get(`${apiVersion}/admin/theiapay/invoices`);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.messages)
    }
  }
}

export async function createInvoice(data: IInvoiceRequest) {
  try {
    const response  = await request.post(`${apiVersion}/theiapay/invoices`, {
      invoice_url: data.invoiceUrl,
      name: data.invoiceName
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.messages)
    }
  }
}

export async function updateInvoice(data:IInvoice) {
  try {
    const body: Record<string, unknown> = {};

    body.status = data.status;
    if(data.fees!=='') body.fees = data.fees;
    if(data.amount!=='') body.amount = data.amount;
    if(data.paymentLink!=='') body.paymentLink = data.paymentLink;

    const response  = await request.put(`${apiVersion}/admin/theiapay/invoices/${data.uid}/${data.pid}`, { ...body });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.messages)
    }
  }
}
